<template>
    <div style="padding-bottom: 50px;">
<!--        <van-sticky>-->
<!--            <van-dropdown-menu>-->
<!--                <van-dropdown-item v-model="value" :options="option" @change="channelChange"/>-->
<!--            </van-dropdown-menu>-->
<!--        </van-sticky>-->

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadData" offset="100">
                <van-notice-bar mode="closeable" wrapable :scrollable="false" v-if="errMsg" :text="errMsg"/>
                <van-empty v-if="finished && !list.length"/>
                <van-card v-for="(item,index) in list"
                          :thumb="item.head_img"
                          @click="detailAlert"
                          :key="index" style="background: #fff;padding-bottom: 10px;">
                    <div slot="title">
                        <span style="font-size: 14px;font-weight: bold;margin-right: 5px;">{{item.shop_name}}</span>
                        <van-tag plain type="primary" style="margin-right: 5px;">{{item.shop_type_name}}</van-tag>
                        <van-tag type="danger" v-if="item.offline" style="margin-right: 5px;">需到店</van-tag>
                        <van-tag plain :type="item.permit && !errMsg? 'success': 'default'" style="margin-right: 10px;">
                            {{item.channel_name}}
                        </van-tag>
                    </div>
                    <div slot="desc" class="card_desc">
                        <p v-if="item.address">店铺地区：{{item.short_name}}</p>
                        <p v-if="item.address">店铺地址：{{item.address}}</p>
                        <p v-if="item.platform">店铺平台：{{item.platform}}</p>
                        <p>探店时间：{{item.start_date}}{{item.start_date === item.end_date?'':'~'+item.end_date}}</p>
                    </div>
                    <div slot="price" style="color:#ee0a24">
                        <span class="van-card__price-currency"></span>
                        <span class="van-card__price-integer">¥&nbsp;{{item.price/100.00}}</span>
                    </div>
                    <div slot="num">
                        <van-button size="mini" round v-if="item.reject_reason" disabled style="padding: 0 10px;">
                            {{item.reject_reason}}
                        </van-button>
                        <van-button size="mini" round disabled style="padding: 0 15px;"
                                    v-if="item.ttl > 0 && !item.tasking">
                            其他达人正在抢单，
                            <van-count-down :time="item.ttl*1000" style="display: inline-block;" format="mm:ss"
                                            @finish="finish(index)"/>
                            <span>&nbsp;后释放</span>
                        </van-button>
                        <van-button size="mini" round color="linear-gradient(to right,#ff6034,#ee0a24)"
                                    v-else-if="item.permit && !errMsg" @click.stop="exploreDetail(item,index)"
                                    style="padding: 0 20px;">
                            <span v-if="item.ttl > 0">
                                继续抢单(<van-count-down v-if="item.ttl > 0" :time="item.ttl*1000" style="display: inline-block;color: #fff;font-size: 10px;" format="mm:ss"
                                                @finish="finish(index)"/>)
                            </span>
                            <span v-else>抢单</span>
                        </van-button>
                    </div>
                </van-card>
            </van-list>
        </van-pull-refresh>
        <explore-service ref="exploreService" @confirmRule="accept" @refuseRule="refuse"></explore-service>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {Dialog, Toast} from 'vant';
    import ExploreService from "../../Common/Dialog/ExploreService";

    export default {
        name: "ExploreListDialog",
        computed: {
            ...mapState(['talent','common']),
        },
        components: {
            ExploreService,
        },
        data() {
            return {
                value: 0,
                option: [
                    {text: '所有等级', value: 0},
                ],
                channel_id: 0,
                list: [],
                refreshing: false,
                finished: false,
                loading: false,
                errMsg: '',
                pageSize: 10,
                pageNo: 1,
                item: {},
                order: false, // 今天是否已经接单
                order_city:'',// 今天已接单的城市id
                order_city_name:'',// 今天已接单的城市
            }
        },
        async mounted() {
            this.post('/api/client/shop/explore/list/before').then(result => {
                if (result.code === 0) {
                    if (result.msg) {
                        this.$toast(result.msg);
                    }
                    if (result.data.order) {
                        this.order = result.data.order;
                        this.order_city = result.data.order_city;
                        this.order_city_name = result.data.order_city_name;
                    }
                } else if (result.code === 1) {
                    this.$dialog.alert({
                        title: '提示',
                        message: result.msg,
                    });
                    this.errMsg = result.msg;
                }else if(result.code === 2){
                    this.errMsg = result.msg;
                } else if (result.code !== 0) {
                    this.$toast(result.msg);
                    this.errMsg = result.msg;
                }
            })
            await this.$store.dispatch('talent/getExploreChannel');
            for (let i = 0; i < this.talent.channels.length; i++) {
                this.option.push(this.talent.channels[i]);
            }
        },
        methods: {
            loadData() {
                let that = this;
                this.post('/api/client/shop/explore/list', {
                    channel_id: this.channel_id,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize
                }).then(result => {
                    // 刷新
                    if (this.refreshing) {
                        this.list = [];
                        this.refreshing = false;
                    }
                    // 加数据
                    for (let i = 0; i < result.data.length; i++) {
                        this.list.push(result.data[i]);
                    }
                    // pageNo++
                    that.pageNo = that.pageNo + 1;
                    if (result.data.length < that.pageSize) { // 结束
                        this.finished = true;
                    }
                    this.loading = false;
                });
            },
            detailAlert(){
                let that = this;
                if(this.errMsg){
                    this.$dialog.alert({
                        title: '提示',
                        message: that.errMsg,
                    });
                }
            },
            finish(index) {
                this.list[index].ttl = 0;
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.refreshing = true;
                this.loading = true;
                this.pageNo = 1;
                this.loadData();
            },
            channelChange(value) {
                this.channel_id = value;
                this.onRefresh();
            },
            exploreDetail(item, index) {
                if (this.errMsg) {
                    this.$dialog({message: this.errMsg});
                    return;
                }
                if (this.order && item.city.toString().slice(0, 4) !== this.order_city.toString().slice(0, 4)){
                    this.$dialog({message: '您今天已在【' + this.order_city_name + '】接单，不可再接其他地区的订单'});
                    return;
                }
                // if(this.common.address.adcode.slice(0, 4) !== item.city.toString().slice(0, 4)){
                //     this.$dialog({message: '您当前的定位不在商家所在城市，如已更换地区请关闭并重新打开当前页面。'});
                //     return;
                // }

                this.item = item;
                this.index = index;
                this.$refs.exploreService.init(3);
            },
            accept() {
                let that = this;
                this.$toast.loading({
                    message: '正在为您抢单...',
                    forbidClick: true,
                    duration: 0,
                })
                let eo_id = this.item.eo_id;
                this.post('/api/client/shop/explore/task', {eo_id: eo_id}).then(result => {
                    if (result.code === 0) {
                        that.$toast.clear();
                        that.$router.push({path: '/talent/task', query: {eo_id: eo_id}});
                    } else {
                        that.$toast.clear();
                        if (result.data.ttl) {
                            this.list[this.index].ttl = result.data.ttl;
                        }
                        Toast(result.msg);
                    }
                }).catch(res => {
                    that.$toast.clear();
                    that.$toast('服务器异常');
                });
                // this.post('/api/client/shop/explore/accept',{eo_id:this.item.eo_id,price:this.item.price}).then(result=>{
                //     if(result.code === 0){
                //         this.$toast.clear();
                //         this.onRefresh();
                //         this.$dialog.alert({
                //             title: '接单成功',
                //             message: '您可在当前页面已接任务中查看',
                //         });
                //     } else {
                //         this.$toast.clear();
                //         this.$dialog.alert({
                //             title: '提示',
                //             message: result.msg,
                //         });
                //     }
                // }).catch(res=>{
                //     this.$toast.clear();
                //     this.$toast('服务器异常');
                // });
            },
            refuse() {
                this.$notify({type: 'warning', message: '请同意来飞网服务条款后接单'});
            },
        }
    }
</script>

<style scoped>
    .card_desc p {
        margin: 3px 0;
    }
</style>